  // Datetime Picker
  $(function () {
    $('.datepicker').datepicker({
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      yearRange: "-120:+0",
      regional: 'el',
      monthNames: ['Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάϊος', 'Ιούνιος',
                'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος'],
      monthNamesShort: ['Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μάϊ', 'Ιούν',
                'Ιούλ', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ'],
      dayNames: ['Κυριακή', 'Δευτέρα', 'Τρίρη', 'Τετάρτη', 
                'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
      dayNamesShort: ['Κυρ', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
      dayNamesMin: ['Κυρ', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
      dateFormat: 'dd-mm-yy', 
    });
});

(function () {
  'use strict'
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl)
  })
})();

// Navbar
(function () {
  'use strict'

  document.querySelector('#navbarSideCollapse').addEventListener('click', function () {
    document.body.classList.toggle('custom-overflow');
    document.querySelector('.offcanvas-collapse').classList.toggle('open')
  })
})()