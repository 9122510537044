require('./bootstrap');

// Select 2 JS
//require('./select2.min');

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';

// Custom
require('./custom');
